import React from 'react'
import { Link } from 'gatsby'
import { categoriesStrings } from '../components/Navigation/menu'
import SEO from '~/components/seo'

const Categories = () => {
  const categories = categoriesStrings.map(item => {
    const slug = item.replace(/\s+/g, '-').toLowerCase()
    return (
      <Link to={`/categories/${slug}`}>
        <div
          style={{
            padding: '12px 16px 8px',
            borderRadius: 12,
            textAlign: 'center',
            backgroundColor: 'rgba(111, 128, 186, 0.08)',
          }}
        >
          {item}
        </div>
      </Link>
    )
  })

  return (
    <>
      <SEO
        title="Categories"
        keywords={[`coverful`, `cushion`, `cushion covers`, `uk`]}
      />
      <div
        style={{
          margin: '64px 0px 48px',
          textAlign: 'center',
          fontSize: 40,
          fontFamily: 'AvenirBold',
        }}
      >
        Categories
      </div>
      <div className="categories-buttons">{categories}</div>
    </>
  )
}

export default Categories
